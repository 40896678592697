@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.navbar {
  width: 100%;
  height: auto;
  padding: 10px 5px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 25% 20% 20% 30%;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: left;
  gap: 10px;
  box-shadow: 0 1px 2px 0 rgba(196, 196, 196, 0.2), 0 2px 5px 0 rgba(173, 173, 173, 0.19);
}

.store-name {
  justify-self: left;
  margin: auto 0 auto 70px;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-stretch: expanded;
  font-weight: 650;
  text-shadow: 0 1px 2px #eaecec9f;
  color: #0291ffb9;
}

.navlist {
  display: flex;
  justify-self: left;
  gap: 0;
}

.nav-item {
  margin: auto 0 auto 0;
  list-style: none;
}

.books,
.catagories {
  color: rgb(2, 2, 2);
  padding: 10px;
  opacity: 0.5;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.7rem;
  letter-spacing: 1.9px;
  text-decoration: none;
}

.books,
.catagories:hover {
  opacity: 1;
  color: #0290ff;
}

.books,
.catagories:active {
  color: #0290ff;
  opacity: 1;
}

.images {
  grid-column: span 2;
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 0 30px 0 0;
  justify-content: right;
}

.profile {
  width: auto;
  height: 1.6rem;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .store-name {
    justify-self: center;
    margin: 0;
  }

  .navlist {
    flex-direction: column;
  }

  .logo {
    margin: 0;
  }
}
